@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

